.edit-notification,
.login {
  width: clamp(0px, calc(85vw - 1rem), 30rem);
  height: fit-content;
  margin-top: 10rem;
  padding: 1.5rem;
  background-color: #1e1e1e;
  border-radius: 1rem;
  font-weight: 500;
  text-align: center;
}
.edit-notification textarea {
  height: 10rem;
}
.edit-notification button {
  margin-bottom: 0;
  width: 50%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  padding: 1rem;
  background-color: #1c1c1c;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.5rem;
  color: white;
  width: calc(100% - 2rem - 2px);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .form-group {
    margin-bottom: 0;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.5s cubic-bezier(0.29, 0.87, 0.47, 1.02);
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.5s cubic-bezier(0.29, 0.87, 0.47, 1.02);
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
