.gallery-container {
  width: 100%;
  margin-top: 4rem;

  img {
    height: 100%;
    object-fit: cover;
  }

  .load-more {
    cursor: pointer;
    padding: 3rem;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  padding: 5vw;
}

@media (min-width: 355px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1440px) {
  .grid-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
