.beitraege-div {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.posts-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 85vw;
  h2,
  p {
    text-align: left;
    margin: 0;
    margin-left: 1rem;
  }
  h2 {
    margin-top: 1rem;
  }
}

.post {
  height: calc((85vw / 3) - 6rem);
  background-color: #1c1c1c;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.post-img {
  width: 100%;
  height: calc((85vw / 3) - 12rem);
  object-fit: cover;
}

.post-icon {
  height: 2rem;
  width: 2rem;
  right: 1rem;
  top: 1rem;
  fill: white;
  box-shadow: 0 0 2rem black;
  background-color: rgba(0, 0, 0, 0.252);
  position: absolute;
}

@media (max-width: 1240px) {
  .posts-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .post {
    height: calc((85vw / 2) - 6rem);
  }
  .post-img {
    height: calc((85vw / 2) - 12rem);
  }
}

@media (max-width: 767px) {
  .posts-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .post {
    height: calc((85vw / 1) - 6rem);
  }
  .post-img {
    height: calc((85vw / 1) - 12rem);
  }
}
