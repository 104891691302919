@import "./colors";
$navbar-max-width: unset;

.navbar-container,
.footer-container {
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 6rem;
  z-index: 100;
}

.navbar-container {
  top: 0;
  position: absolute;
  background-color: transparent;
  width: 100vw;
}

.active {
  top: 0;
  position: fixed;
  visibility: visible;
  transition: all 0.5s;
}

.hidden {
  top: 0;
  position: absolute;
  visibility: visible;
  transition: all 0.5s;
}

.showbackground {
  transition: all 0.5s;
}

.hidebackground {
  background: none;
  transition: all 0.5s;
}

.footer-container {
  bottom: 0;
  background-color: black;
  padding-top: 3rem;
}

.navbar-mobile {
  background: rgba(0, 0, 0, 0.3); /* Apply background color */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;

  .links {
    gap: 1rem;
  }
}

.navbar-mobile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* Apply blur effect only to the background */
  -webkit-backdrop-filter: blur(10px);
  z-index: -1;
}

.navbar,
.dropdown {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.dropdown {
  background: rgba(0, 0, 0, 0.5);
}

.navbar,
.navbar-mobile,
.footer,
.footer-mobile {
  position: absolute;
  width: 85%;
  max-width: $navbar-max-width;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin-top: 1rem;
  border-radius: 2rem;

  .logo {
    margin: 0;
    margin-top: -0.15rem;
    z-index: 100;
    height: 3rem;
  }
}

.footer-mobile {
  flex-direction: column;
  z-index: 99;
}
.footer-mobile-split {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.footer-contact {
  padding-left: 1rem;
}

.nav-links {
  font-weight: bold;
  padding: 1rem;

  &:hover {
    color: $navbar-color;
  }
}

.links {
  margin-left: auto;
  display: flex;
  align-items: center;
  z-index: 99;
  height: 3rem;
}

.active-class {
  color: $navbar-color;
}

.dropdown {
  display: none;
  position: absolute;
  text-align: right;
  top: 5.5rem;
  right: 0;
  z-index: 200;
  border-radius: 1rem;
  overflow: hidden;
}

.more {
  padding: 3rem 0 3rem 1rem;
  &:hover {
    .chevron-down-stroke {
      stroke: $navbar-color;
    }
    .dropdown {
      display: flex;
      flex-direction: column;
    }
  }
}

.dropdown-link {
  padding-left: 2rem;
  padding: 1rem;
}

.chevron-down-svg {
  height: 1.2rem;
  margin-bottom: -0.2rem;
}

.navbar-mobile,
.footer-mobile {
  display: none;
}

.dropdown-margin {
  margin-top: 1rem;
  border-radius: 1rem;
}

.dropdown-bottom {
  border-radius: 1rem;
}

.cross {
  display: none;
  z-index: 300;
}

.mobile-menu-contact {
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
  bottom: 2rem;
  width: 100vw;
  font-size: larger;
}

.mobile-menu-social {
  position: absolute;
  bottom: 6rem;
  height: 2.5rem;
  width: 100%;
  .social-links {
    fill: white;
    height: 2.5rem;
    width: 2.5rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }
  .navbar-mobile {
    display: flex;
  }
  .dropdown-link {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    margin: 0;
    padding: 1rem;
    padding-left: 20vw;
    text-align: left;
    font-size: larger;
  }
  .dropdown-margin {
    margin: 0;
    border: none;
  }

  .hamburger {
    display: block;
    z-index: 100;

    &.hidden {
      display: none;
    }
  }

  .cross {
    display: block;

    &.hidden {
      display: none;
    }
  }

  .dropdown {
    display: flex;
    border-radius: 0;
    border: none;
    flex-direction: column;
    position: fixed;
    text-align: center;
    top: 0;
    right: 0;
    z-index: 200;
    height: 100svh;
    width: 100vw;
    justify-content: center;
    &.hidden {
      display: none;
    }

    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: none;
  }

  .dropdown-first {
    margin-top: -4rem;
  }
}

@media (max-width: 650px) {
  .footer-mobile {
    display: flex;
  }
  .footer {
    display: none;
  }
  .footer-container {
    padding-bottom: 6rem;
    margin-top: 5rem;
    margin-bottom: -10rem;
  }
  .links-footer {
    margin-right: auto;
    font-size: larger;
  }
  .footer-contact {
    padding: 0;
  }
}
