.post-container {
  width: 85vw;
  max-width: 1000px;

  h1 {
    margin-bottom: 3rem;
  }
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  h3 {
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  h4 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: lightgray;
  }
}
