@import "./colors";
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
$height1: 75vh;
$max-width-p: 30rem;
$border-radius: 1rem;
$border-radius-small: 0.5rem;

html {
  background-color: black;
}

h1 {
  margin-bottom: 1rem;
  letter-spacing: -0.05rem;
}

h2 {
  letter-spacing: -0.05rem;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

h4 {
  margin-top: 3rem;
}

img {
  user-select: none;
}

* {
  color: $text-color;
}

p {
  color: #979797;
  line-height: 1.5rem;
  text-align: left;
}

body {
  overflow-x: hidden;
  overflow-y: visible;
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

a {
  text-decoration: none;
}

.home {
  .image-container {
    position: relative;
    width: 100vw;
    height: $height1;

    .background {
      width: 100%;
      height: 100%;
      .background-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .foreground,
    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      width: 100vw;
      height: $height1;
      background-color: rgba(0, 0, 0, 0.45);
      .gradient {
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 0%
        );
      }
      .logo {
        width: clamp(15rem, 90%, 22rem);
        z-index: 3;
      }
      .main-title {
        padding-top: 5rem;
        padding-left: 7.5vw;
        font-size: clamp(2.7rem, 4.5vw, 4rem);
        @media (max-width: 450px) {
          font-size: clamp(1rem, 9.7vw, 5rem);
        }
        text-align: left;
        margin-bottom: 0.5rem;
      }
      .main-p {
        width: clamp(12rem, 40vw, 30rem);
        padding-left: 7.5vw;
        text-align: left;
        color: white;
        margin-bottom: 1rem;
        margin-top: 0;
        margin-bottom: 1.4rem;
      }
      .main-button-container {
        background-color: $primary-color;
        margin-left: 7.5vw;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem 0rem 0.8rem 1rem;
        border-radius: 1rem;

        box-shadow: 0 4px 30px rgba(0, 255, 0, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.5);

        .main-button {
          color: white;
          font-weight: bold;
          font-size: 1rem;
        }

        .main-right {
          transition: transform 0.5s ease-in-out;
          animation: arrowMove 0.5s infinite alternate ease-in-out;
        }

        @keyframes arrowMove {
          0% {
            transform: translateX(-5px);
          }
          100% {
            transform: translateX(5px);
          }
        }
      }
    }
  }
}

.flex {
  display: flex;
  width: 85vw;
  justify-content: center;
  align-items: center;
}
.flex-radius {
  border-radius: $border-radius;
  overflow: hidden;
}

.banner {
  padding: 0;
  height: 16rem;
  margin-top: clamp(1rem, 7.5vw, 3rem);
  border-radius: $border-radius;
  overflow: hidden;
  align-items: center;
  &:hover .banner-img {
    transform: scale(1.1);
    filter: brightness(80%);
  }
}

button {
  font-size: 1rem;
  font-weight: 600;
  width: fit-content;
  background-color: $primary-color;
  padding: 1rem;
  border: none;
  border-radius: $border-radius;
  margin-bottom: 1rem;
  &:hover {
    background-color: $secondary-color;
    cursor: pointer;
  }
}

.flex-column,
.flex-center {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}

.flex-center {
  width: 100%;
}

.flex-column-grey {
  background-color: #1c1c1c;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
}

.flex-banner-text {
  margin: 0 2rem 1.2rem 2rem;
  max-width: $max-width-p;
}

.banner-img {
  min-height: 100%;
  width: 100%;
  object-fit: cover;

  transition: transform 1.5s cubic-bezier(0.29, 0.87, 0.47, 1.02),
    filter 1.5s cubic-bezier(0.29, 0.87, 0.47, 1.02);
  transform: scale(1);
  filter: brightness(100%);
  &:hover {
    transform: scale(1.3);
    filter: brightness(50%);
  }
}

.banner-right {
  overflow: hidden;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content,
.home-content {
  background-color: $background-color;
  width: 100vw;
}

.content {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 9.3rem);
}

.title {
  margin-top: 9rem;
}

.phone-link {
  color: $navbar-color;
}

.full-width {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.logo-animated,
.logo-animated-2 {
  height: 100%;
  width: 100%;
  object-fit: contain;
  .lazy-load-image-loaded,
  .lazy-load-image-background {
    width: 100%;
  }
}

.img-zoom {
  object-fit: contain;
  transition: transform 0.5s cubic-bezier(0.29, 0.87, 0.47, 1.02);
  transform: scale(1);
  filter: brightness(100%);
  &:hover {
    transform: scale(0.9);
  }
}

.img-zoom-in {
  object-fit: contain;
  transition: transform 0.5s cubic-bezier(0.29, 0.87, 0.47, 1.02);
  transform: scale(1);
  filter: brightness(100%);
  &:hover {
    transform: scale(1.1);
  }
}

.brand-showcase {
  margin-top: 3rem;
  height: 20rem;
}

.partner-showcase {
  margin-top: 3rem;
  height: 16rem;
}

.sponsor-showcase {
  margin-top: 3rem;
  height: 20rem;
}

.google-rate {
  margin-top: clamp(1px, 7.5vw, 3rem);
  height: 20rem;
}

.partner-button {
  background-color: #3d3d3d;
  box-shadow: 0 4px 30px rgba(54, 54, 54, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.bright-button {
  color: white;
  margin-bottom: 1.7rem;
  box-shadow: 0 4px 30px rgba(0, 255, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.logo-carousel {
  margin: 0.5rem;
  height: 100%;
  white-space: nowrap;
  align-items: center;
}

.logo-carousel-slide {
  display: inline-block;
  animation: 10s slide infinite linear;
}

.logo-carousel-slide-sponsor {
  display: inline-block;
  animation: 17s slide infinite linear;
}

.logo-carousel-slide img,
.logo-carousel-slide a {
  height: 12rem;
  margin: 0 0.25rem;
  border-radius: 100%;
}

.social-links:first-child {
  margin-right: 0.5rem;
}

.social-links:last-child {
  margin-left: 0.5rem;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.flex-carousel {
  align-items: start !important;
  overflow: hidden;
  width: 70%;
}

.logo-carousel-slide-sponsor img {
  height: 20rem;
  margin: 0 0.25rem;
  margin-left: -0.5rem;
  object-fit: cover;
}

.parcours-infos-height {
  min-height: 35rem;
  width: clamp(0px, 85vw, 60rem);
  margin: auto;
}

.oeffnungszeiten-table {
  max-width: 25rem;
  margin-top: 1rem;
  margin-left: 2rem;
}

.preise-table-1,
.preise-table-2 {
  margin-top: 1rem;
  margin-left: 2rem;
  max-width: 40rem;
}

.preise-sommer-2023 {
  width: 100%;
}

.preise-title {
  color: #ffff;
  text-align: left;
  margin-left: 2rem;
}

.oeffnungszeiten {
  width: 100%;
}

.foot-note {
  margin-top: 1rem;
  font-size: small;
  line-height: 1rem;
}

td {
  width: clamp(4rem, 20vw, 6rem);
  height: 4rem;
}

th {
  height: 3rem;
}

.td-tall {
  height: 6rem;
}

th,
td {
  padding: 0 0.25rem;
  text-align: left;
}

.runden-karte {
  width: 80vw;
  border-radius: $border-radius;
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin: auto;

  label {
    margin-bottom: 5px;
    text-align: left;
    margin-left: 0.5rem;
  }

  input,
  textarea,
  select {
    text-align: left;
    box-sizing: border-box;
    color: white;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: $border-radius-small;
    background-color: #1c1c1c;
    width: clamp(1rem, 85vw, 30rem);
    border-style: none;
  }

  .accept-rules {
    display: flex;
    align-items: center;
    width: clamp(1rem, 85vw, 25rem);

    input {
      margin-right: 5px;
    }
  }

  .error-message {
    color: red;
  }

  .checkbox {
    width: auto;
    margin: 0;
  }

  .rule-accept {
    margin: 0;
    margin-left: 0.5rem;
  }
  .form-button {
    margin-top: 1rem;
    width: 100%;
  }
}

.red-text {
  color: red;
}

.flex-form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.modal-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-dialog img {
  max-width: 90%;
  max-height: 90%;
}

.cursor-poitner {
  cursor: pointer;
}

.gallery-close {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 1.5rem 5vw 1.5rem 1.5rem;
}

.dot {
  display: inline-block;
  margin-right: -100px;
  margin-bottom: -100px;
  right: 5vw;
  bottom: 1.5rem;
  height: 200px;
  width: 200px;
  background-color: #000000;
  border-radius: 50%;
  position: fixed;
}

.gallery-arrow-left {
  position: fixed;
  left: 0;
  padding: 20vh 20vw 20vh 5vw;
}

.gallery-arrow-right {
  position: fixed;
  right: 0;
  padding: 20vh 5vw 20vh 20vw;
}

.sucess-message {
  display: none;
  position: fixed;
  bottom: 50%;
  border-radius: 1rem;
  background-color: $primary-color;
  padding: 1rem;
}

.show {
  display: block;
}

.datenschutz-text {
  text-align: left;
}

.contact {
  font-weight: bold;
}

.h2-parcours,
.h2-parcours-no-margin {
  text-align: left;
}
.h2-parcours {
  margin-left: 2rem;
}

@media (max-width: 767px) {
  .parcours-infos-height {
    flex-direction: column;
  }
  .flex-column {
    width: 100%;
  }

  .banner .flex,
  .banner {
    flex-direction: column-reverse;
    min-height: 30rem;
    align-items: center;
  }
  .banner-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .brand-showcase .flex,
  .brand-showcase {
    flex-direction: column-reverse;
    min-height: 30rem;
    align-items: center;
  }
  .logo-animated {
    width: clamp(5rem, 90%, 25rem);
  }
  .logo-animated-2 {
    height: 100%;
    width: 100%;
  }
  .brand-showcase {
    margin-top: 1rem;
    height: auto;
  }
  .partner-showcase .flex,
  .partner-showcase {
    flex-direction: column;
    min-height: 30rem;
    align-items: center;
  }
  .sponsor-showcase .flex,
  .sponsor-showcase {
    flex-direction: column-reverse;
    min-height: 40rem;
    align-items: center;
  }
  .sponsor-showcase {
    margin-top: 0;
  }
  .google-rate .flex,
  .google-rate {
    flex-direction: column;
    min-height: 35rem;
    align-items: center;
  }
  .background-image {
    object-position: top;
  }

  .preise-table-1,
  .preise-table-2 {
    margin-left: 2rem;
  }

  .oeffnungszeiten,
  .preise-sommer-2023 {
    width: 85vw;
    max-width: 30rem;
  }
}

.map-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.map-link {
  height: 100%;
  width: 100%;
}

.star {
  position: relative;

  display: inline-block;
  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 0.9em;

  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.3em solid transparent;

  font-size: 10px;

  &:before,
  &:after {
    content: "";

    display: block;
    width: 0;
    height: 0;

    position: absolute;
    top: 0.6em;
    left: -1em;

    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 1em solid transparent;

    transform: rotate(-35deg);
  }

  &:after {
    transform: rotate(35deg);
  }
}
