.notify-container {
  transition: all 0.5s;
  padding: 1rem;
  position: fixed;
  top: 7rem;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 20rem;
  height: 6rem;
  z-index: 100;
  background: #c62828;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1rem;
  .close-btn {
    top: 0;
    right: 0;
    position: absolute;
    background-color: transparent;
  }
  .notify-content {
    h1,
    p {
      color: white;
    }
  }
}

@media (max-width: 768px) {
  .notify-container {
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    margin: 1rem auto;
    width: calc(100% - 2rem);
  }
}
