/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #000000; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 3px; /* Round the edges of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #4b4b4b; /* Color of the thumb on hover */
}
