.language-switcher {
  position: relative;
  height: 100%;
}

.language-button {
  border: 1px solid gray;
  background: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  height: 100%;
}

.language-button:hover {
  background: none;
}
