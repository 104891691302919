.datenschutz-text {
  word-wrap: break-word;
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: lightgray;
  }
}
